// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('jquery')
require("trix")
require("@rails/actiontext")
require("@nathanvda/cocoon")

require("orders")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import 'bootstrap';
import '@fortawesome/fontawesome-free/js/all';
import '../stylesheets/application';
import "../stylesheets/actiontext";
import 'trix/dist/trix.css'
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
$(document).on('turbolinks:load', function() {
    $(function() {
        setTimeout("$('.alert').fadeOut('slow')", 3000);
    });
    $('table.table-hover tr[data-href]').addClass('clickable');
  $('table.table-hover tr[data-href]').on('click',function(e) {
    if(!$(e.target).is('a')){
      window.location = $(e.target).closest('tr').data('href');
    };
  });
});