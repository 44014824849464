$(document).on('turbolinks:load', function() {
  console.log('orders.js')
  //delivery_unit_priceにdelivery_feeを代入
  $(".nested-fields").each(function() {
    var select = $(this).find('.order_order_deliveries_delivery select');
    var delivery_unit_price = $(this).find('.order_order_deliveries_delivery_unit_price input');

    $(select).on('change',function(){
      var tax_value = $(this).parents('.simple_form.h-adr').find('#order_taxes option:selected').val();
      console.log(tax_value);
      if (tax_value == 'included'){
        var delivery_selected_price = $('option:selected',this).attr('data-fee');
        var delivery_selected_price =　Math.round(delivery_selected_price * 1.1);
      } else {
        var delivery_selected_price = $('option:selected',this).attr('data-fee');
      }
      $(delivery_unit_price).val(delivery_selected_price);
      var delivery_quantity = $(this).parents('.nested-fields').find('.order_order_deliveries_delivery_quantity input').first().val();
      var delivery_subtotal_price = parseInt(delivery_selected_price) * parseInt(delivery_quantity)
      if (isNaN(delivery_subtotal_price)) {
        $(this).parents('.nested-fields').find('.order_order_deliveries_delivery_subtotal_price input').val("0").change();
      }
      else {
        $(this).parents('.nested-fields').find('.order_order_deliveries_delivery_subtotal_price input').val("" + delivery_subtotal_price).change();
      }
    });
  });

  var $children = $('#order_delivery_hour');
  var original = $children.html();

  $('#order_delivery_company').on('change',function() {
    var val1 = $(this).val();
    $children.html(original).find('option').each(function() {
      var val2 = $(this).data('val');
      if (val1 != val2) {
        $(this).not(':first-child').remove();
      }
    });

    if ($(this).val() == "") {
      $children.attr('disabled', 'disabled');
    } else {
      $children.removeAttr('disabled');
    }
  });

  var $children = $('#order_delivery_hour');
  var original = $children.html();
  var val1 = $('#order_delivery_company').val();

  $children.html(original).find('option').each(function() {
    var val2 = $(this).data('val');
    if (val1 != val2) {
      $(this).not(':first-child').remove();
    }
  });

  if ($('#order_delivery_company').val() == "") {
    $children.attr('disabled', 'disabled');
  } else {
    $children.removeAttr('disabled');
  };

  //検索後合計の計算
  function calSearchTotal() {
    var oderTotalPrice = 0;
    var oderTotalPriceStr ='';
    $( 'table.order_result tr.order_result_tr' ).each(function() {
      var price = parseInt($(this).find('td.order_result_price').html());
      oderTotalPrice += price;
      oderTotalPriceStr = String(oderTotalPrice).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
      price = String(price).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
      $(this).find('td.order_result_price').html(price);

    });
    $( 'h2.order_result_total' ).html(oderTotalPriceStr.toString());
    $( 'h2.order_result_total' ).append("円");
    $( 'td.order_result_price' ).append("円");
    $( 'h2.order_result_total' ).prepend("合計金額：");

  }
  //初めに表示した時に計算
  calSearchTotal();

  $(function() {
  $(this).on('change', '.order_order_details_sponsor_ship input, .order_order_details_unit_price input, .order_order_details_quantity input', function() {
    var unit_price = $(this).parents('.nested-fields').find('.order_order_details_unit_price input').first().val();
    var sponsor_ship = $(this).parents('.nested-fields').find('.order_order_details_sponsor_ship input').first().val();
    var quantity = $(this).parents('.nested-fields').find('.order_order_details_quantity input').first().val();

    if (unit_price) {
      var subtotal_price = parseInt(unit_price) * parseInt(quantity);
    }

    if (isNaN(quantity)) {
      if (isNaN(sponsor_ship)) {
        $(this).val(0).change();
        $(this).parents('.nested-fields').find('.order_order_details_subtotal_price input').val("0").change();
      } else {
        var subtotal_quantity = parseInt(sponsor_ship);
        $(this).parents('.nested-fields').find('.order_order_details_subtotal_quantity input').val("" + subtotal_quantity).change();
      }
      $(this).val(0).change();
    } else {
      if (isNaN(sponsor_ship)) {
        var subtotal_quantity = parseInt(quantity);
        $(this).parents('.nested-fields').find('.order_order_details_subtotal_quantity input').val("" + subtotal_quantity).change();
      } else {
        var subtotal_price = parseInt(unit_price) * parseInt(quantity);
      }
    }

    if (isNaN(subtotal_price)) {
      $(this).parents('.nested-fields').find('.order_order_details_subtotal_price input').val("0").change();
    } else {
      $(this).parents('.nested-fields').find('.order_order_details_subtotal_price input').val("" + subtotal_price).change();
    }

    if (isNaN(sponsor_ship)) {
      if (isNaN(quantity)) {
        $(this).parents('.nested-fields').find('.order_order_details_subtotal_quantity input').val('0').change();
      } else {
        var subtotal_quantity = parseInt(quantity);
        $(this).parents('.nested-fields').find('.order_order_details_subtotal_quantity input').val("" + subtotal_quantity).change();
      }
    } else {
      if (isNaN(quantity)) {
        var subtotal_quantity = parseInt(sponsor_ship);
        $(this).parents('.nested-fields').find('.order_order_details_subtotal_quantity input').val("" + subtotal_quantity).change();
      } else {
        var subtotal_quantity = parseInt(quantity) + parseInt(sponsor_ship);
        $(this).parents('.nested-fields').find('.order_order_details_subtotal_quantity input').val("" + subtotal_quantity).change();
      }
    }

    if (subtotal_quantity) {
      $(this).parents('.nested-fields').find('.order_order_details_subtotal_quantity input').val("" + subtotal_quantity).change();
    }
  });

  $(this).on('change', '.order_order_promotions_unit_price input, .order_order_promotions_quantity input', function() {
    var unit_price = $(this)
      .parents('.nested-fields')
      .find('.order_order_promotions_unit_price input')
      .first()
      .val();
    var quantity = $(this)
      .parents('.nested-fields')
      .find('.order_order_promotions_quantity input')
      .first()
      .val();
    if (unit_price) {
      var subtotal_price = parseInt(unit_price) * parseInt(quantity);
    }
    if (isNaN(subtotal_price)) {
      $(this)
        .parents('.nested-fields')
        .find('.order_order_promotions_subtotal_price input')
        .val("0")
        .change();
    } else {
      $(this)
        .parents('.nested-fields')
        .find('.order_order_promotions_subtotal_price input')
        .val(`${subtotal_price}`)
        .change();
    }
  });

  $(this).on('change', '.order_order_deliveries_delivery_quantity input, .order_order_deliveries_delivery_unit_price input', function() {
    var delivery_unit_price = $(this)
      .parents('.nested-fields')
      .find('.order_order_deliveries_delivery_unit_price input')
      .first()
      .val();
    var delivery_quantity = $(this)
      .parents('.nested-fields')
      .find('.order_order_deliveries_delivery_quantity input')
      .first()
      .val();

    var delivery_subtotal_price = parseInt(delivery_unit_price) * parseInt(delivery_quantity);

    if (isNaN(delivery_subtotal_price)) {
      $(this)
        .parents('.nested-fields')
        .find('.order_order_deliveries_delivery_subtotal_price input')
        .val("0")
        .change();
    } else {
      $(this)
        .parents('.nested-fields')
        .find('.order_order_deliveries_delivery_subtotal_price input')
        .val(`${delivery_subtotal_price}`)
        .change();
    }
  });

  $(this).on('change', '.order_order_details_subtotal_price input, .order_order_deliveries_delivery_subtotal_price input, .order_order_promotions_subtotal_price input', function() {
    var order_total = 0;
    $(this)
      .parents()
      .find('.nested-fields:visible .order_order_details_subtotal_price input')
      .each(function() {
        order_total += parseInt($(this).val());
      });
    $(this)
      .parents()
      .find('.nested-fields:visible .order_order_promotions_subtotal_price input')
      .each(function() {
        order_total += parseInt($(this).val());
      });
    $(this)
      .parents()
      .find('.nested-fields:visible .order_order_deliveries_delivery_subtotal_price input')
      .each(function() {
        order_total += parseInt($(this).val());
      });
    $('.order_total_price input').val(`${order_total}`);
  });

  $(this).on('change', '.order_order_details_subtotal_quantity input', function() {
    var quantity_total = 0;
    $(this)
      .parents()
      .find('.nested-fields:visible .order_order_details_subtotal_quantity input')
      .each(function() {
        quantity_total += parseInt($(this).val());
      });
    $('.order_total_quantity input').val(`${quantity_total}`);
    $('.goukeihonsuu span').text(`${quantity_total}`);
  });
});

  // coccon for order_detail
  $('#order_details').on('cocoon:before-insert', function(e, task_to_be_added) {
    task_to_be_added.fadeIn('slow');
    return;
  });
  $('#order_details').on('cocoon:after-insert', function(e, added_task) {
    return;
  });
  $('#order_details').on('cocoon:before-remove', function(e, task) {
    $(this).data('remove-timeout', 1000);
    task.fadeOut('slow');
    return;
  });
  $('#order_details').on('cocoon:after-remove', function(e, calc_task) {
    var total_price = 0;
    var total_quantity = 0;
    $('#order_details .nested-fields:visible').each(function() {
      var order_subtotal_quantity = $(this).find('.order_order_details_subtotal_quantity input');
      total_quantity += parseInt($(order_subtotal_quantity).val());
      var order_subtotal = $(this).find('.order_order_details_subtotal_price input');
      total_price += parseInt($(order_subtotal).val());
    });

    $('#order_promotions .nested-fields:visible').each(function() {
      var order_promotion_subtotal = $(this).find('.order_order_promotions_subtotal_price input');
      total_price += parseInt($(order_promotion_subtotal).val());
    });

    $('#order_deliveries .nested-fields:visible').each(function() {
      var order_delivery_subtotal = $(this).find('.order_order_deliveries_delivery_subtotal_price input');
      total_price += parseInt($(order_delivery_subtotal).val());
    });

    $('.order_total_price input').val(`${total_price}`);
    $('.order_total_quantity input').val(`${total_quantity}`);
  });

  // coccon for order_delivery
  $('#order_deliveries').on('cocoon:before-insert', function(e, task_to_be_added) {
    task_to_be_added.fadeIn('slow');
    return;
  });
  
  $('#order_deliveries').on('cocoon:after-insert', function(e, added_task) {
    $('.nested-fields').each(function() {
      var select = $(this).find('.order_order_deliveries_delivery select');
      var delivery_unit_price = $(this).find('.order_order_deliveries_delivery_unit_price input');
      var delivery_quantity = $(this).parents('.nested-fields').find('.order_order_deliveries_delivery_quantity input').first().val();

      $(select).on('change', function() {
        var tax_value = $(this).parents('.simple_form.h-adr').find('#order_taxes option:selected').val();
        var value;
        if (tax_value == 'included') {
          value = $('option:selected', this).attr('data-fee') * 1.1;
          value = Math.round(value);
          console.log(value);
        } else {
          value = $('option:selected', this).attr('data-fee');
          console.log(value);
        }

        if (isNaN(value)) {
          $(delivery_unit_price).val("0");
          $(this)
            .parents('.nested-fields')
            .find('.order_order_deliveries_delivery_subtotal_price input')
            .val("0")
            .change();
        } else {
          $(delivery_unit_price).val(value);
          delivery_quantity = $(this).parents('.nested-fields').find('.order_order_deliveries_delivery_quantity input').first().val();
          var delivery_subtotal_price = parseInt(value) * parseInt(delivery_quantity);
          $(this)
            .parents('.nested-fields')
            .find('.order_order_deliveries_delivery_subtotal_price input')
            .val(`${delivery_subtotal_price}`)
            .change();
        }
        return;
      });
      return;
    });
  });

  $('#order_deliveries').on('cocoon:before-remove', function(e, task) {
    $(this).data('remove-timeout', 1000);
    task.fadeOut('slow');
    return;
  });

  $('#order_deliveries').on('cocoon:after-remove', function(e, calc_task) {
    var total_price = 0;
    $('#order_details .nested-fields:visible').each(function() {
      var order_subtotal = $(this).find('.order_order_details_subtotal_price input');
      total_price += parseInt($(order_subtotal).val());
    });

    $('#order_promotions .nested-fields:visible').each(function() {
      var order_promotion_subtotal = $(this).find('.order_order_promotions_subtotal_price input');
      total_price += parseInt($(order_promotion_subtotal).val());
    });

    $('#order_deliveries .nested-fields:visible').each(function() {
      var order_delivery_subtotal = $(this).find('.order_order_deliveries_delivery_subtotal_price input');
      total_price += parseInt($(order_delivery_subtotal).val());
    });

    $('.order_total_price input').val(`${total_price}`);
  });

  $('#order_promotions').on('cocoon:before-insert', function(e, task_to_be_added) {
    task_to_be_added.fadeIn('slow');
    return;
  });

  $('#order_promotions').on('cocoon:after-insert', function(e, added_task) {
    return;
  });

  $('#order_promotions').on('cocoon:before-remove', function(e, task) {
    $(this).data('remove-timeout', 1000);
    task.fadeOut('slow');
    return;
  });

  $('#order_promotions').on('cocoon:after-remove', function(e, calc_task) {
    var total_price = 0;
    $('#order_details .nested-fields:visible').each(function() {
      var order_subtotal = $(this).find('.order_order_details_subtotal_price input');
      total_price += parseInt($(order_subtotal).val());
    });

    $('#order_promotions .nested-fields:visible').each(function() {
      var order_promotion_subtotal = $(this).find('.order_order_promotions_subtotal_price input');
      total_price += parseInt($(order_promotion_subtotal).val());
    });

    $('#order_deliveries .nested-fields:visible').each(function() {
      var order_delivery_subtotal = $(this).find('.order_order_deliveries_delivery_subtotal_price input');
      total_price += parseInt($(order_delivery_subtotal).val());
    });

    $('.order_total_price input').val(`${total_price}`);
    $('.order_total_quantity input').val(`${total_quantity}`);
  });

  $('.table td.about_seikyu:contains(\'コレクト\')').addClass('text-danger');

  // 合計本数の値を取得
  var totalQuantity = $("input[name='order[total_quantity]']").val();
  // 取得した値をspan要素にセット
  $(".goukeihonsuu span").text(totalQuantity);

  $(".row.order_search_block input").addClass("form-control");
});
